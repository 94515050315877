/* eslint-disable @typescript-eslint/no-unused-vars */
import { DatePipe, IMAGE_CONFIG, IMAGE_LOADER, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
//import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { provideRouter, withDebugTracing, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { de } from 'date-fns/locale';
import { provideMarkdown } from 'ngx-markdown';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { AuthJwtModule } from './modules/auth-jwt/auth-jwt.module';
import { provideAccessToken } from './modules/auth-jwt/tools/provide-access-token';
import { provideDebugModule } from './modules/debug';
import { FeatureDynamicService } from './modules/feature-dynamic';
import { NavInfoService } from './modules/nav-info';
import { BreakpointService } from './services/breakpoint.service';
import { imageLoader } from './shared/image-loader';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { provideHttpClient } from '@angular/common/http';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';


registerLocaleData(localeDe, 'de');

function initializeAppFactory(
    breakpointService: BreakpointService,
    navInfoService: NavInfoService,
    featureDynamicService: FeatureDynamicService
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): () => Observable<any> {
    return () => of(null);
}


export const appConfig: ApplicationConfig = {
    providers: [
        // {provide: NgZone, useClass: NoopNgZone}, // to disable zone.js
        // provideZoneChangeDetection(),
        provideRouter(routes,
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
            withViewTransitions({ skipInitialTransition: true }),
            // withDebugTracing()
        ),
        provideHttpClient(),
        provideAnimations(),
        //provideAnimationsAsync(),
        provideMarkdown(),
        provideDebugModule({
            templateDebugging: !environment.production,
            loggingType: environment.production ? 'NONE' : 'ALL', // 'ALL',
        }),
        importProvidersFrom(
            StoreModule.forRoot({}, {}),
            // StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true }),
            AuthJwtModule.forRoot({
                config: {
                    tokenGetter: () => provideAccessToken(),
                    allowedDomains: ['ystm.eu', 'localhost:3000', 'pd907d6b2.dip0.t-ipconnect.de', 'yachting-service.de'],
                    disallowedRoutes: [`${environment.ystmServiceBaseUrl}/auth/refresh`, `${environment.ystmServiceBaseUrl}/auth/sign-in`, `${environment.ystmServiceBaseUrl}/auth/sign-out`]
                }
            }),
        ),
        provideDateFnsAdapter(),
        DatePipe,
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: MAT_DATE_LOCALE, useValue: de },
        { provide: MAT_CARD_CONFIG, useValue: {appearance: 'outlined'}},
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', hideRequiredMarker: false } },
        // { provide: MAT_MENU_SCROLL_STRATEGY, useClass: NoopScrollStrategy, multi: true },
        {
            provide: IMAGE_LOADER,
            useValue: imageLoader
            // useValue: (config: ImageLoaderConfig) => {
            //   switch (environment.line) {
            //     case 'production':
            //       return config.width ? `http://tahaa:6914/unsafe/fit-in/${config.width}x/https://ystm.eu/${config.src}` : `${config.src}`;
            //     case 'staging':
            //       return config.width ? `http://tahaa:6914/unsafe/fit-in/${config.width}x/https://ystm.eu/${config.src}` : `${config.src}`;
            //     case 'development':
            //       return config.width ? `http://localhost:6914/unsafe/fit-in/${config.width}x/http://172.23.179.3:8080/${config.src}` : `${config.src}`;
            //   }

            //   // http://tahaa:6914/unsafe/fit-in/480x/https://ystm.eu/assets/img/grund1.jpg
            // },
        },
        {
            provide: IMAGE_CONFIG, useValue: {
                breakpoints: [
                    16, // Angular default
                    32, // Angular default
                    48, // Angular default
                    64, // Angular default
                    96, // Angular default
                    128, // Angular default
                    256, // Angular default
                    384, // Angular default
                    640, // Angular default
                    750, // Angular default
                    828, // Angular default
                    960, // manually inserted value
                    1080, // Angular default
                    1140, // Mid size between 1080 and 1200
                    1200, // Angular default
                    1560, // Mid size between 1200 and 1920
                    1920, // Angular default
                    2048, // Angular default
                    3840 // Angular default
                ]
            }
        },
        /** injection of NavInfoService and other services on start-up */
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [BreakpointService, NavInfoService, FeatureDynamicService],
            multi: true
        }
    ]
};